<template>
  <div class="search">
    <Card>
      <Row v-show="openSearch" @keydown.enter.native="handleSearch">
        <Collapse value="1" accordion style="width:100%">
          <Panel name="1">查询条件
            <p slot="content">
              <Form ref="searchForm" :model="searchForm" :label-width="100" label-position="right">
                <Row :gutter="32">
                  <Col span="7">
                  <FormItem label="受害人姓名" prop="shr">
                    <Input type="text" v-model="searchForm.shr" :maxlength="15" placeholder="请输入受害人姓名" clearable />
                  </FormItem>
                  </Col>
                  <Col span="7">
                  <FormItem label="伤残等级" prop="scdj">
                    <Select v-model="searchForm.scdj" clearable>
                      <Option v-for="(item, i) in this.$store.state.dict.scdj" :key="i" :value="item.value">
                        {{ item.title }}</Option>
                    </Select>
                  </FormItem>
                  </Col>
                </Row>
              </Form>
            </p>
          </Panel>
        </Collapse>
      </Row>
      <Row @keydown.enter.native="handleSearch">
        <Form>
          <Form-item style="margin-top: 10px" class="br">
            <Button @click="handleSearch" type="primary" icon="ios-search">搜索</Button>
            <Button @click="add" type="warning" icon="md-add">添加</Button>
          </Form-item>
        </Form>
      </Row>
      <Row>
        <Table :loading="loading" border :columns="columns" :data="data" ref="table" sortable="custom"
          @on-sort-change="changeSort" @on-selection-change="changeSelect"></Table>
      </Row>
      <Row type="flex" justify="end" class="page">
        <Page :current="searchForm.pageNumber" :total="total" :page-size="searchForm.pageSize" @on-change="changePage"
          @on-page-size-change="changePageSize" :page-size-opts="[10, 20, 50]" size="small" show-total show-elevator
          show-sizer></Page>
      </Row>
    </Card>
    <gspcAddEdit :data="formData" :addEditFlag="addEditFlag" v-model="showGspcAddEdit" @on-submit="getDataList" />
  </div>
</template>

<script>
  // 根据你的实际请求api.js位置路径修改
  import {
    getGspcList,
    deleteGspc
  } from "@/api/busi/gspc-manage/gspc";
  // 根据你的实际添加编辑组件位置路径修改
  import gspcAddEdit from "./gspcAddEdit.vue";
  export default {
    name: "gspc",
    components: {
      gspcAddEdit,
    },
    data() {
      return {
        openSearch: true, // 显示搜索
        formData: {},
        addEditFlag: "1",
        loading: true, // 表单加载状态
        showGspcAddEdit: false,
        searchForm: {
          // 搜索框初始化对象
          pageNumber: 1, // 当前页数
          pageSize: 10, // 页面大小
          sort: "createTime", // 默认排序字段
          order: "desc", // 默认排序方式
          shr: "",
          scdj: -1,
        },
        columns: [{
            type: "index",
            width: 60,
            align: "center",
          },
          {
            title: "受害人姓名",
            key: "shr",
            minWidth: 120,
            align: "center",
            sortable: false,
            render: (h, params) => {
              return h(
                "div", {
                  style: {
                    textAlign: "left",
                  },
                },
                [
                  h(
                    "span", {
                      style: {},
                    },
                    params.row.shr
                  ),
                ]
              );
            },
          },
          {
            title: "受害日期",
            key: "shrq",
            minWidth: 120,
            align: "center",
            sortable: false,
          },
          {
            title: "伤残等级",
            key: "scdj",
            align: "center",
            minWidth: 120,
            sortable: false,
            render: (h, params) => {
              let re = "";
              if (params.row.scdj == 0) {
                re = "不构成伤残";
              } else if (params.row.scdj == 1) {
                re = "十级";
              } else if (params.row.scdj == 2) {
                re = "九级";
              } else if (params.row.scdj == 3) {
                re = "八级";
              } else if (params.row.scdj == 4) {
                re = "七级";
              } else if (params.row.scdj == 5) {
                re = "六级";
              } else if (params.row.scdj == 6) {
                re = "五级";
              } else if (params.row.scdj == 7) {
                re = "四级";
              } else if (params.row.scdj == 8) {
                re = "三级";
              } else if (params.row.scdj == 9) {
                re = "二级";
              } else if (params.row.scdj == 10) {
                re = "一级";
              } else if (params.row.scdj == 11) {
                re = "死亡";
              }
              return h("div", re);
            },
          },
          {
            title: "一次性赔偿金额",
            key: "ze",
            minWidth: 120,
            align: "center",
            sortable: false,
            render: (h, params) => {
              return h(
                "div", {
                  style: {
                    textAlign: "right",
                  },
                },
                [
                  h(
                    "span", {
                      style: {},
                    },
                    parseFloat(params.row.ze).toFixed(2)
                  ),
                ]
              );
            },
          },
          {
            title: "操作",
            key: "action",
            align: "center",
            width: 200,
            render: (h, params) => {
              return h("div", [
                h(
                  "Button", {
                    props: {
                      type: "success",
                      size: "small",
                      icon: "ios-create-outline",
                    },
                    style: {
                      marginRight: "5px",
                    },
                    on: {
                      click: () => {
                        this.edit(params.row);
                      },
                    },
                  },
                  "编辑"
                ),
                h(
                  "Button", {
                    props: {
                      type: "warning",
                      size: "small",
                      icon: "md-trash",
                    },
                    on: {
                      click: () => {
                        this.remove(params.row);
                      },
                    },
                  },
                  "删除"
                ),
              ]);
            },
          },
        ],
        data: [], // 表单数据
        pageNumber: 1, // 当前页数
        pageSize: 10, // 页面大小
        total: 0, // 表单数据总数
      };
    },
    methods: {
      init() {
        this.getDataList();
      },
      submited() {
        this.getDataList();
      },
      changePage(v) {
        this.searchForm.pageNumber = v;
        this.getDataList();
        this.clearSelectAll();
      },
      changePageSize(v) {
        this.searchForm.pageSize = v;
        this.getDataList();
      },
      handleSearch() {
        this.searchForm.pageNumber = 1;
        this.searchForm.pageSize = 10;
        this.getDataList();
      },
      changeSort(e) {
        this.searchForm.sort = e.key;
        this.searchForm.order = e.order;
        if (e.order === "normal") {
          this.searchForm.order = "";
        }
        this.getDataList();
      },
      getDataList() {
        if (this.searchForm.scdj == undefined) this.searchForm.scdj = -1;
        this.loading = true;
        getGspcList(this.searchForm).then((res) => {
          this.loading = false;
          if (res.success) {
            this.data = res.result.content;
            this.total = res.result.totalElements;
          }
        });
      },
      add() {
        this.addEditFlag = "1";
        this.showGspcAddEdit = true;
      },
      edit(v) {
        // 转换null为""
        for (let attr in v) {
          if (v[attr] == null) {
            v[attr] = "";
          }
        }
        let str = JSON.stringify(v);
        let data = JSON.parse(str);
        this.addEditFlag = "2";
        this.formData = data;
        this.showGspcAddEdit = true;
      },
      remove(v) {
        this.$Modal.confirm({
          title: "确认删除",
          // 记得确认修改此处
          content: "您确认要删除 " + v.shr + " ?",
          loading: true,
          onOk: () => {
            // 删除
            deleteGspc({
              ids: v.id,
            }).then((res) => {
              this.$Modal.remove();
              if (res.success) {
                this.$Message.success("操作成功");
                this.getDataList();
              }
            });
          },
        });
      },
    },
    mounted() {
      this.init();
    },
  };
</script>
<style lang="less">
  // 建议引入通用样式 具体路径自行修改 可删除下面样式代码
  // @import "../../../styles/table-common.less";
  .search {
    .operation {
      margin-bottom: 2vh;
    }

    .select-count {
      font-weight: 600;
      color: #40a9ff;
    }

    .select-clear {
      margin-left: 10px;
    }

    .page {
      margin-top: 2vh;
    }

    .drop-down {
      margin-left: 5px;
    }
  }
</style>